// const dotenv = require("dotenv");
// dotenv.config();

let apiUrl;

console.log("e");
const APP_ENV = process.env.VUE_APP_ENV;
if (APP_ENV == "production") {
  console.log("p");
  apiUrl = "https://www.eripbee.in/api/v1";
} else if (APP_ENV == "development") {
  console.log("d");
  apiUrl = "https://bee.nutshell.repair/api/v1";
} else {
  console.log("local");
  // apiUrl = "http://127.0.0.1:8000/api/v1";
  // apiUrl = "https://bee.nutshell.repair/api/v1";
  apiUrl = "https://www.eripbee.in/api/v1";
}

//
const config = {
  apiUrl: apiUrl,
};

module.exports = config;
