<template>
  <div class="py-5">
    <!--  -->

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="text-center">
            <img
              src="@/assets/images/erip_logo_white.png"
              alt="erip"
              height="70"
              class="mx-auto mb-4"
            />
          </div>

          <div class="card">
            <div class="card-body">
              <div class="text-center mb-4">
                <h5 class="text-uppercase mt-0">Sign In</h5>
              </div>

              <div class="mt-3" v-if="fbWrapperShow">
                <div class="alert" role="alert" :class="fbDiv">
                  <div v-html="fbMsg"></div>
                </div>
              </div>

              <form method="post" id="empLoginOtpForm" v-if="form1Show">
                <div class="mb-3">
                  <label for="otpMobile" class="form-label"
                    >Mobile Number</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    id="otpMobile"
                    name="otpMobile"
                    v-model="mobile"
                    placeholder="Enter your Mobile Number"
                    required
                  />
                </div>
                <div class="mb-3 d-grid text-center">
                  <button
                    @click=sendLoginOtp
                    type="submit"
                    class="btn btn-primary"
                    :disabled="buttonDisabled"
                  >
                    {{buttonText}}
                  </button>
                </div>
              </form>

              <form method="post" id="empLoginForm" v-if="form2Show">
                <div class="mb-3">
                  <label for="mobile" class="form-label">Mobile Number</label>
                  <input
                    class="form-control"
                    type="text"
                    id="mobile"
                    name="mobile"
                    v-model="mobile"
                    placeholder="Enter your Mobile Number"
                    required
                    readonly
                  />
                </div>
                <div class="mb-3">
                  <label for="otp" class="form-label">OTP</label>
                  <input
                    class="form-control"
                    type="text"
                    id="otp"
                    name="otp"
                    v-model="otp"
                    placeholder="Enter your OTP"
                    required
                  />
                </div>
                <div class="mb-3 d-grid text-center">
                  <button
                    @click=sendLoginCreds
                    type="submit"
                    class="btn btn-primary"
                    :disabled="buttonDisabled"
                  >
                    {{buttonText}}
                  </button>
                </div>
              </form>

            </div>
          </div>

        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>


<script>

import axios from "axios";
import { POST_SEND_LOGIN_OTP, POST_SEND_LOGIN_CREDENTIALS } from "@/services/ApiConstants";

export default ({
  data() {
    return {
      fbWrapperShow: false,
      fbDiv: '',
      fbMsg: '',
      buttonDisabled: false,
      buttonText: 'Login',
      form1Show: true,
      form2Show: false,
      mobile: '',
      otp: '',
    }
  },
    methods: {
      async sendLoginOtp(e) {
        e.preventDefault();
        this.fbWrapperShow = false;
        this.fbDiv = '';
        this.fbMsg = '';
        this.buttonDisabled = true;
        this.buttonText = 'Please wait...';
        let formData = {
          mobile: this.mobile
        };
        let reqUrl = `${POST_SEND_LOGIN_OTP}`;
        axios.post(reqUrl, formData)
        .then(response => {
          let responseData  = response.data;
          let msg = "";
          if(responseData.success) {
            msg = responseData.message;
            this.fbDiv = 'alert-success';
            this.form1Show = false;
            this.form2Show = true;
          } else {
            if(responseData.status == "ok") {
              msg = responseData.message;
            } else {
              for (const key in responseData.message) {
                msg += responseData.message[key];
              }
            }
            this.fbDiv = 'alert-danger';
          }
          this.fbWrapperShow = true;
          this.fbMsg = msg;
          this.buttonDisabled = false;
          this.buttonText = 'Login';
        })
        .catch(error => {
          // let response = JSON.parse(error.request.response);
          // let msg = response.message;
          console.log(error.response);
          this.fbWrapperShow = true;
          this.fbDiv = 'alert-danger';
          this.fbMsg = "Server error";
          this.buttonDisabled = false;
          this.buttonText = 'Login';
        });
      },
      async sendLoginCreds(e) {
        e.preventDefault();
        this.fbWrapperShow = false;
        this.fbDiv = '';
        this.fbMsg = '';
        this.buttonDisabled = true;
        this.buttonText = 'Please wait...';
        let formData = {
          mobile: this.mobile,
          otp: this.otp
        };
        let reqUrl = `${POST_SEND_LOGIN_CREDENTIALS}`;
        axios.post(reqUrl, formData)
        .then(response => {
          let responseData  = response.data;
          let msg = "";
          if(responseData.success) {
            msg = responseData.message;
            this.fbDiv = 'alert-success';
            localStorage.setItem("token", responseData.authorisation.token);
            localStorage.setItem("emp_id", responseData.emp_data.emp_id);
            localStorage.setItem("emp_fullname", responseData.emp_data.emp_fullname);
            localStorage.setItem("emp_mobile", responseData.emp_data.emp_mobile);
            localStorage.setItem("emp_email", responseData.emp_data.emp_email);
            window.setInterval(window.location.reload(), 5000)
          } else {
            if(responseData.status == "ok") {
              msg = responseData.message;
            } else {
              for (const key in responseData.message) {
                msg += responseData.message[key];
              }
            }
            this.fbDiv = 'alert-danger';
            this.buttonDisabled = false;
            this.buttonText = 'Login';
          }
          this.fbWrapperShow = true;
          this.fbMsg = msg;
          // window.location.reload();
          // window.setInterval()
        })
        .catch(error => {
          // let response = JSON.parse(error.request.response);
          // let msg = response.message;
          console.log(error.response);
          this.fbWrapperShow = true;
          this.fbDiv = 'alert-danger';
          this.fbMsg = "Server error";
          this.buttonDisabled = false;
          this.buttonText = 'Login';
        });

      },
    },
    async created() {
      this.loaderShow = false;
    }
  })

</script>

<style scoped>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .card {
    padding: 30px 15px;
    border: 0px solid #ffffff;
  }

</style>
