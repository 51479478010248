<template>
  <div>
    <!--  -->

    <div class="row">
      <div class="col-9">
        <h2>Vendors - Add</h2>
      </div>
      <div class="col-3">
        <router-link to="/vendors" class="btn btn-danger btn-sm float-end">Cancel</router-link>
      </div>
    </div>
  
    <Loader :loaderShow="loaderShow"/>

    <Feedback :fbWrapperShow="fbWrapperShow" :fbDiv="fbDiv" :fbMsg="fbMsg"/>
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            
            <div class="col-md-8 offset-md-2 col-sm-12">
              <h4 class="mt-3 mb-3 text-center">Fill below form to add vendor</h4>
              <hr>
              <form>
                <div class="mb-3">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td style="width: 50%;">Latitude</td>
                        <td>{{addressLatitude}} {{ latLongStatus }}</td>
                      </tr>
                      <tr>
                        <td>Longitude</td>
                        <td>{{addressLongitude}} {{ latLongStatus }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" id="mobileNumber" v-model="mobileNumber" placeholder="Enter Mobile Number" required>
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" id="proprietorName" v-model="proprietorName" placeholder="Enter Owner Name" required>
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" id="businessName" v-model="businessName" placeholder="Enter Business Name" required>
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" id="addressNo" v-model="addressNo" placeholder="Shop No" required>
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" id="addressLine1" v-model="addressLine1" placeholder="Address Line 1" required>
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" id="addressLine2" v-model="addressLine2" placeholder="Address Line 2" required>
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" id="addressLandmark" v-model="addressLandmark" placeholder="Landmark" required>
                </div>
                <div class="mb-3">
                  <select class="form-select" id="cities" v-model="addressCity" required>
                    <option value="">Select City</option>
                    <option v-for="item in cities" :value="item.loc_city_id" :key="item.id">{{item.loc_city_title}}</option>
                  </select>
                </div>
                <div class="mb-3">
                  <select class="form-select" id="pincodes" v-model="addressPincode" required>
                    <option value="">Select Pincode</option>
                    <option v-for="item in pincodes" :value="item.pincode_id" :key="item.id">{{item.pincode_title}}</option>
                  </select>
                </div>
                <div class="mt-4 mb-3">
                  <button type="button" @click=addVendor class="btn btn-primary float-end">Submit</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>


<script>

import { CommonServices } from "@/services/CommonServices";
import Loader from "@/components/Loader.vue";
import Feedback from "@/components/Feedback.vue";

// import axios from "axios";
// import { POST_VENDORS } from "@/services/ApiConstants";

export default ({
  components: {
    Feedback,
    Loader,
  },
  data() {
      return {
        loaderShow: true,
        fbWrapperShow: false,
        fbDiv: '',
        fbMsg: '',
        mobileNumber: '',
        proprietorName: '',
        businessName: '',
        addressNo: '',
        addressLine1: '',
        addressLine2: '',
        addressLandmark: '',
        addressCity: '',
        addressPincode: '',
        addressLatitude: '',
        addressLongitude: '',
        addressCurrentPosition: [],
        cities: [],
        pincodes: [],
        buttonDisabled: false,
        latLongStatus: '???',
      }
  },
  watch: {
    // selectedCity(newValues) {
    //   if (newValues.includes('selectAll')) {
    //     this.selectedCategories = this.categories.map(categories => categories.category_id);
    //   }
    //   }
    addressCity(city) {
      if (city !== "") {
        console.log('newValues');
        console.log(city);
      }
    }
  },
  methods: {
    async fetchCities() {
      let response = await CommonServices.getCities();
      if(response.success) {
        this.cities = response.data;
      }
    },
    async detectLatLong() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(currentPosition => {
          this.addressLatitude = currentPosition.coords.latitude;
          this.addressLongitude = currentPosition.coords.longitude;
          this.addressCurrentPosition = currentPosition;
          this.latLongStatus = '';
        })
      } else {
        this.fbWrapperShow = true;
        this.fbDiv = 'alert-danger';
        this.fbMsg = 'Geolocation is not supported in this browser';
      }
    },
    async addVendor(e) {
      e.preventDefault();
      // this.buttonDisabled = true;

      let formData = {
        mobileNumber: this.mobileNumber,
        proprietorName: this.proprietorName,
        businessName: this.businessName,
        addressNo: this.addressNo,
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        addressLandmark: this.addressLandmark,
        addressCity: this.addressCity,
        addressPincode: this.addressPincode,
        addressLatitude: this.addressLatitude,
        addressLongitude: this.addressLongitude,
        addressCurrentPosition: this.addressCurrentPosition,
      };
      console.log('formData');
      console.log(formData);
      // let reqUrl = `${POST_VENDORS}`;
      // let token = await CommonServices.getUserToken();
      // let headers = {
      //   headers: {
      //     Authorization: `Bearer ${token}`
      //   }};
      // axios.post(reqUrl, formData, headers)
      // .then(response => {
      //   let responseData  = response.data;
      //   console.log('responseData');
      //   console.log(responseData);
      // })
      // .catch(error => {
      //   console.log(error.response);
      //   let msg = "Server error";
      //   this.loaderShow = false;
      //   this.fbWrapperShow = true;
      //   this.fbDiv = 'alert-danger';
      //   this.fbMsg = msg;
      // });
    },
  },
  async created(){
    await this.fetchCities();
    await this.detectLatLong();
    this.loaderShow = false;
  }
})






/* 


      console.log('addVendor button clicked');
          this.latLongStatusClasss = 'text-success';
        // latLongClass: 'bg-danger',
        // latLongStatus: '<span class="text-danger">Not Ok</span>',
        latLongStatusContent: '???',
        latLongStatusClass: 'text-danger',
          <!-- <td>{{addressLatitude}} <span :class="latLongStatusClass">{{ latLongStatusContent }}</span></td> -->
          <!-- <td>{{addressLongitude}} <span :class="latLongStatusClass">{{ latLongStatusContent }}</span></td> -->
          <!-- <td>{{addressLatitude}} {{ latLongStatus }}</td> -->
          <!-- <td>{{addressLatitude}} <span :class="latLongStatus">{{ latLongStatus }}</span></td> -->
          <!-- <td>{{addressLongitude}} {{ latLongStatus }}</td> -->
          <!-- <td>{{addressLongitude}} <span :class="latLongClass"></span></td> -->
          // console.log('currentPosition');
          // console.log(currentPosition);
          // console.log(JSON.stringify(currentPosition, 0, 2));
          console.log('addressCurrentPosition');
          console.log(this.addressCurrentPosition);
        // let response = JSON.parse(error.request.response);
        // let msg = response.message;
        // this.buttonDisabled = false;

              :disabled="buttonDisabled"
                              <!-- <div class="mb-3">
                                <button type="submit" @click=mapsLocation class="btn btn-secondary btn-sm" v-if="showLocationBtn">Detect Location</button>
                              </div> -->
              
                      showLocationBtn: true,
                      // console.log('this.addressLatitude');
                      // console.log(this.addressLatitude);
                      // console.log(this.addressLongitude);
                      // if(this.addressLatitude == "" || this.addressLongitude =="") {
                      //   this.fbWrapperShow = true;
                      //   this.fbDiv = 'alert-danger';
                      //   this.fbMsg = 'Geolocation detection failed';
                      // }
              
                    // this.showLocationBtn = false;
                    // document.getElementById("btnDetectLatLong").val('hi');
                    // console.log(e.target);
                    // e.target.innerText = "Detecting Location...!";
                      // alert('Geolocation is not supported in this browser')
                    // this.showLocationBtn = true;
              
                      // currLocation: '',
                        // console.log('currentPosition');
                        // console.log(currentPosition);
                        // this.currLocation = {
                        //   latitude: currentPosition.coords.latitude,
                        //   longitude: currentPosition.coords.longitude
                        // }
                    // }
                    // console.log('currLocation');
                    // console.log(this.currLocation);
                    // https://maps.google.com/?q=[lat],[long]
                    // https://maps.google.com/?q=-37.866963,144.980615
              
              
                                <!-- <label for="mobileNumber" class="form-label">Mobile Number</label> -->
                                <!-- <label for="proprietorName" class="form-label">Proprietor Name</label> -->
                                <!-- <label for="businessName" class="form-label">Business Name</label> -->
                                <!-- <label for="address" class="form-label">Address Details</label> -->
                                <!-- <label for="addressMaps" class="form-label">Address Maps</label> -->
              <div class="mb-3">
                <label for="mobileNumber" class="form-label">Mobile Number</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="mobileNumber" v-model="mobileNumber" placeholder="Enter Mobile Number" required>
                </div>
              </div>
              <div class="mb-3">
                <label for="proprietorName" class="form-label">Proprietor Name</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="proprietorName" v-model="proprietorName" placeholder="Enter Owner Name" required>
                </div>
              </div>
              <div class="mb-3">
                <label for="businessName" class="form-label">Business Name</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="businessName" v-model="businessName" placeholder="Enter Business Name" required>
                </div>
              </div>
              <div class="mb-3">
                <label for="address" class="form-label">Address Details</label>
                <div class="col-sm-3">
                  <input type="text" class="form-control" id="addressNo" v-model="addressNo" placeholder="Shop No" required>
                </div>
                <div class="col-sm-6">
                  <input type="text" class="form-control" id="addressLine1" v-model="addressLine1" placeholder="Address Line 1" required>
                </div>
              </div>
              <div class="mb-3">
                <label for="address" class="form-label"></label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" id="addressLine2" v-model="addressLine2" placeholder="Address Line 2" required>
                </div>
                <div class="col-sm-3">
                  <input type="text" class="form-control" id="addressLandmark" v-model="addressLandmark" placeholder="Landmark" required>
                </div>
              </div>
              <div class="mb-3">
                <label for="address" class="form-label"></label>
                <div class="col-sm-6">
                  <select class="form-select" id="cities" v-model="addressCity" required>
                    <option value="">Select City</option>
                    <option v-for="item in cities" :value="item.city_id" :key="item.id">{{item.city_title}}</option>
                  </select>
                  <!-- <input type="text" class="form-control" id="addressCity" v-model="addressCity" required> -->
                </div>
                <div class="col-sm-3">
                  <select class="form-select" id="pincodes" v-model="addressPincode" required>
                    <option value="">Select Pincode</option>
                    <option v-for="item in pincodes" :value="item.pincode_id" :key="item.id">{{item.pincode_title}}</option>
                  </select>
                  <!-- <input type="text" class="form-control" id="addressPincode" v-model="addressPincode" required> -->
                </div>
              </div>
              <div class="mb-3">
                <label for="addressMaps" class="form-label">Address Maps</label>
                <div class="col-sm-3">
                  <button type="button" class="btn btn-secondary btn-sm">Deduct Maps</button>
                </div>
              </div>
              <div class="mb-3">
                <div class="col-sm-9 offset-3">
                  <button type="submit" @click=addVendor class="btn btn-primary" :disabled="buttonDisabled">Submit</button>
                </div>
              </div>
              */
</script>