<template>
  <div>
    <!--  -->
  
    <div class="row">
      <div class="col-12">
        <h2>Reports - Not Interested</h2>
      </div>
    </div>
  
    <Loader :loaderShow="loaderShow"/>
  
    <Feedback :fbWrapperShow="fbWrapperShow" :fbDiv="fbDiv" :fbMsg="fbMsg"/>
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form class="row g-3">
              <div class="col-auto">
                <label for="fromDate">From Date*</label>
                <input type="date" class="form-control" id="fromDate" v-model="fromDate" required/> 
              </div>
              <div class="col-auto">
                <label for="toDate">To Date*</label>
                <input type="date" class="form-control" id="toDate" v-model="toDate" required/> 
              </div>
              <div class="col-auto">
                <label for="categories">Categories*</label>
                <select class="form-select" id="categories" v-model="selectedCategories" multiple required>
                  <option value="selectAll" :disabled="selectAllDisabled">Select All</option>
                  <option v-for="item in categories" :value="item.category_id" :key="item.id">{{item.category_title}}</option>
                </select>
              </div>
              <div class="col-auto">
                <label for="">&nbsp;</label>
                <button type="submit" @click=getReport class="btn btn-primary form-control" :disabled="buttonDisabled">Get Report</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row mt-3" v-if="tableRowShow">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">
              <button type="button" @click=exportToExcel class="btn btn-primary btn-sm">Export to Excel <span class="badge bg-light text-dark ms-1" v-if="dataCountLabel !== ''">{{dataCountLabel}}</span></button>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-sm" id="notInterestedTable">
                <thead class="table-secondary">
                  <tr>
                    <th>Order ID</th>
                    <th>Order Date</th>
                    <th>Order Time</th>
                    <th>Month</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>Model/Segment</th>
                    <th>Issue</th>
                    <th>Service Type</th>
                    <th>Issue Price</th>
                    <th>Parts Price</th>
                    <th>Extra Charges Price</th>
                    <th>Discount</th>
                    <th>Coupon</th>
                    <th>Sub Total</th>
                    <th>Convience Fee</th>
                    <th>Grand Total</th>
                    <th>Customer Name</th>
                    <th>Customer Mobile</th>
                    <th>Source</th>
                    <th>Sub Source</th>
                    <th>Cancelled By</th>
                    <th>Cancelled At</th>
                    <th>Cancelled Reason</th>
                    <th>Cancelled Description</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- tableRowData -->
                  <tr v-for="item in tableRowData" :key="item.id">
                    <td>{{ item.order_id }}</td>
                    <td>{{ item.order_date }}</td>
                    <td>{{ item.order_time }}</td>
                    <td>{{ item.order_month }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.brand }}</td>
                    <td>{{ item.model_segment }}</td>
                    <td>{{ item.issues }}</td>
                    <td>{{ item.service_type }}</td>
                    <td class="text-end">{{ item.issues_total_price }}</td>
                    <td class="text-end">{{ item.parts_price }}</td>
                    <td class="text-end">{{ item.extra_charges_price }}</td>
                    <td class="text-end">{{ item.discounts }}</td>
                    <td class="text-end">{{ item.coupons }}</td>
                    <td class="text-end">{{ item.sub_total }}</td>
                    <td class="text-end">{{ item.convenience_fee }}</td>
                    <td class="text-end">{{ item.order_final_price }}</td>
                    <td>{{ item.user_fullname }}</td>
                    <td>{{ item.user_mobile }}</td>
                    <td>{{ item.source }}</td>
                    <td>{{ item.sub_source }}</td>
                    <td>{{ item.not_interested_by }}</td>
                    <td>{{ item.not_interested_at }}</td>
                    <td>{{ item.not_interested_reason }}</td>
                    <td>{{ item.not_interested_desc }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>


<script>

import { CommonServices } from "@/services/CommonServices";
import Loader from "@/components/Loader.vue";
import Feedback from "@/components/Feedback.vue";

import * as XLSX from "xlsx";
import axios from "axios";
import { GET_REPORTS_NOT_INTERESTED } from "@/services/ApiConstants";
// import { ReportsServices } from "@/services/ReportsServices";

export default ({
  components: {
    Feedback,
    Loader,
  },
  data() {
      return {
        loaderShow: true,
        fbWrapperShow: false,
        fbDiv: '',
        fbMsg: '',
        fromDate: '',
        toDate: '',
        categories: [],
        selectedCategories: [],
        tableRowShow: false,
        tableRowData: [],
        buttonDisabled: false,
        dataCountLabel: '',
        selectAllDisabled: true,
      }
    },
    watch: {
      selectedCategories(newValues) {
        if (newValues.includes('selectAll')) {
          this.selectedCategories = this.categories.map(categories => categories.category_id);
        }
    }
  },
    methods: {
      async fetchCategories() {
        let response = await CommonServices.getAllCategories();
        if(response.success) {
          this.categories = response.data;
        }
        if(this.categories.length > 1) {
          this.selectAllDisabled = false;
        }
      },
      async getReport(e) {
        e.preventDefault();
        this.buttonDisabled = true;
        this.loaderShow = true;
        this.tableRowShow = false;
        this.fbWrapperShow = false;
        this.fbDiv = '';
        this.fbMsg = '';
        this.dataCountLabel = '';
        let categoriesparams = "";
        for(let i = 0; i < this.selectedCategories.length; i++){
          categoriesparams += ("&category[]="+this.selectedCategories[i]);
        }
        let reqUrl = `${GET_REPORTS_NOT_INTERESTED}?city=1&from_date=${this.fromDate}&to_date=${this.toDate}${categoriesparams}`;
        let token = await CommonServices.getUserToken();
        axios.get(reqUrl, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          let responseData  = response.data;
          // console.log('responseData');
          // console.log(responseData);
          if(responseData.success) {
            const data = responseData.data;
            this.dataCountLabel = responseData.data_count;
            let monthNames = [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December'
            ];
            const dateFormatOptions = {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              timeZone: 'Asia/Kolkata'
            };
            this.tableRowData = [];
            for (let x in data) {
              let order_id = data[x].order_id;
              let order_date = data[x].order_date;
              let order_time = data[x].order_time;
              let user_fullname = data[x].user_fullname.toUpperCase();
              let user_mobile = data[x].user_mobile;
              let category = data[x].category;
              let brand = data[x].brand;
              let model_segment = data[x].model_segment;
              let order_issues = data[x].order_issues;
              let order_parts = data[x].order_parts;
              let order_extra_charges = data[x].order_extra_charges;
              let order_discounts_emp_data = data[x].order_discounts_emp_data;
              let order_promo_codes_data = data[x].order_promo_codes_data;
              let order_fee_taxs = data[x].order_fee_taxs;
              let order_final_price = data[x].order_final_price;
              let source = data[x].source_type;
              let sub_source = data[x].sub_source_type;
              let order_not_interested = data[x].order_not_interested;

              const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);
              
              const date = new Date(order_date);
              let order_month = monthNames[date.getMonth()];
              order_date = date.toLocaleDateString('en-IN', dateFormatOptions);
              
              let service_type = "";
              let issues = "";
              let issues_total_price = 0;
              if (order_issues.length > 0) {
                for (let r2 in order_issues) {
                  issues += order_issues[r2].issue_name+", ";
                  issues_total_price += parseFloat(order_issues[r2].issue_discounted_price);
                  service_type += capitalizeFirstLetter(order_issues[r2].issue_repair_type)+", ";
                }
                issues = issues.slice(0, -2);
                service_type = service_type.slice(0, -2);
              }
              issues_total_price = issues_total_price.toFixed(2);

              let parts_price = 0;
              if (order_parts.length > 0) {
                for (let r3 in order_parts) {
                  parts_price += parseFloat(order_parts[r3].part_price);
                }
              }
              parts_price = parts_price.toFixed(2);

              let extra_charges_price = 0;
              if (order_extra_charges.length > 0) {
                for (let r4 in order_extra_charges) {
                  extra_charges_price += parseFloat(order_extra_charges[r4].charge_price);
                }
              }
              extra_charges_price = extra_charges_price.toFixed(2);

              let discounts = 0;
              if (order_discounts_emp_data.length > 0) {
                for (let r5 in order_discounts_emp_data) {
                  discounts += parseFloat(order_discounts_emp_data[r5].discount_price);
                }
              }
              discounts = discounts.toFixed(2);

              let coupons = 0;
              if (order_promo_codes_data.length > 0) {
                for (let r6 in order_promo_codes_data) {
                  coupons += parseFloat(order_promo_codes_data[r6].pc_amount);
                }
              }
              coupons = coupons.toFixed(2);

              let convenience_fee = 0;
              if (order_fee_taxs.length > 0) {
                for (let r7 in order_fee_taxs) {
                  convenience_fee += parseFloat(order_fee_taxs[r7].ocf_amount);
                }
              }
              convenience_fee = convenience_fee.toFixed(2);
              
              let sub_total = parseFloat(issues_total_price) + parseFloat(parts_price) + parseFloat(extra_charges_price)-parseFloat(discounts)-parseFloat(coupons);
              sub_total = sub_total.toFixed(2);
              order_final_price = order_final_price.toFixed(2);

              let not_interested_by = "";
              let not_interested_at = "";
              let not_interested_reason = "";
              let not_interested_desc = "";
              if (order_not_interested.length > 0) {
                for (let r8 in order_not_interested) {
                  not_interested_by = order_not_interested[r8].creator_name.toUpperCase();
                  not_interested_at = order_not_interested[r8].created_at;
                  not_interested_reason = order_not_interested[r8].oni_reason;
                  not_interested_desc = order_not_interested[r8].oni_description;
                }
              }

              // 
              this.tableRowData = [...this.tableRowData, {
                "order_id" : order_id,
                "order_date" : order_date,
                "order_time" : order_time,
                "order_month" : order_month,
                "category" : category,
                "brand" : brand,
                "model_segment" : model_segment,
                "issues" : issues,
                "service_type" : service_type,
                "issues_total_price" : issues_total_price,
                "parts_price" : parts_price,
                "extra_charges_price" : extra_charges_price,
                "discounts" : discounts,
                "coupons" : coupons,
                "sub_total" : sub_total,
                "convenience_fee" : convenience_fee,
                "order_final_price" : order_final_price,
                "user_fullname" : user_fullname,
                "user_mobile" : user_mobile,
                "source" : source,
                "sub_source" : sub_source,
                "not_interested_by" : not_interested_by,
                "not_interested_at" : not_interested_at,
                "not_interested_reason" : not_interested_reason,
                "not_interested_desc" : not_interested_desc,
              }];
              this.buttonDisabled = false;
              this.loaderShow = false;
              this.tableRowShow = true;
              this.fbWrapperShow = false;
              this.fbDiv = '';
              this.fbMsg = '';
            }
          } else {
            let message = "";
            if(responseData.status == "not_ok") {
              let message1 = responseData.message;
              message = "<ul>";
              for (const key in message1) {
                message += "<li>"+message1[key][0]+"</li>";
              }
              message += "</ul>";
            } else {
              message = "<div>"+responseData.message+"</div>";
            }
            this.buttonDisabled = false;
            this.loaderShow = false;
            this.tableRowShow = false;
            this.fbWrapperShow = true;
            this.fbDiv = 'alert-danger';
            this.fbMsg = message;
          }
        })
        .catch(error => {
          // let response = JSON.parse(error.request.response);
          // let msg = response.message;
          console.log(error.response);
          let msg = "Server error";
          this.buttonDisabled = false;
          this.loaderShow = false;
          this.tableRowShow = false;
          this.fbWrapperShow = true;
          this.fbDiv = 'alert-danger';
          this.fbMsg = msg;
        });
      },
      exportToExcel(e) {
        e.preventDefault();
        const table = document.querySelector('#notInterestedTable')
        const worksheet = XLSX.utils.table_to_sheet(table)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        let fileName = "myData.xlsx"; 
        if(this.fromDate != "" && this.toDate != "") {
          fileName = "AFPL_not_interested_"+this.fromDate+"_to_"+this.toDate+".xlsx";
        } else {
          fileName = "AFPL_not_interested.xlsx";
        }
        XLSX.writeFile(workbook, fileName)
      },
    },
    async created(){
      await this.fetchCategories();
      this.loaderShow = false;
    }
  })

</script>