import jwtDecode from "jwt-decode";

export function AuthGuard(to, from, next) {
  let isAuthenticated = false;
  // const title = to.meta.title;
  // if (title) {
  //   document.title = title + " - MUSAFIR";
  // } else {
  //   document.title = "MUSAFIR";
  // }
  // window.document.title = title ? to.meta.title : "MUSAFIR";

  const token = localStorage.getItem("token");
  if (token != null) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.token_expires_at > currentTime) {
        // Token is not expired, allow access to the route
        isAuthenticated = true;
        // next();
        // if (to.name == "login") {
        //   next("/dashboard");
        // } else {
        //   next();
        // }
        // } else {
        // Token is expired, redirect to the login page
        // next("/login");
      }
    } catch (error) {
      // Invalid token, redirect to the login page
      // next("/login");
    }
    // } else {
    // No token found, redirect to the login page
    // next("/login");
  }

  if (isAuthenticated) {
    next();
  } else {
    next("/login");
  }
}
