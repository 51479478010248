<template>
  <div>
    <!--  -->

    <div class="row">
      <div class="col-12">
        <h2>About us</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim reprehenderit magni necessitatibus architecto facilis eveniet? Repellat a modi minima saepe nihil, inventore totam impedit magni reiciendis, similique quas, quos pariatur. Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim reprehenderit magni necessitatibus architecto facilis eveniet? Repellat a modi minima saepe nihil, inventore totam impedit magni reiciendis, similique quas, quos pariatur. Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim reprehenderit magni necessitatibus architecto facilis eveniet? Repellat a modi minima saepe nihil, inventore totam impedit magni reiciendis, similique quas, quos pariatur.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim reprehenderit magni necessitatibus architecto facilis eveniet? Repellat a modi minima saepe nihil, inventore totam impedit magni reiciendis, similique quas, quos pariatur. Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim reprehenderit magni necessitatibus architecto facilis eveniet? Repellat a modi minima saepe nihil, inventore totam impedit magni reiciendis, similique quas, quos pariatur. Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim reprehenderit magni necessitatibus architecto facilis eveniet? Repellat a modi minima saepe nihil, inventore totam impedit magni reiciendis, similique quas, quos pariatur.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim reprehenderit magni necessitatibus architecto facilis eveniet? Repellat a modi minima saepe nihil, inventore totam impedit magni reiciendis, similique quas, quos pariatur. Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim reprehenderit magni necessitatibus architecto facilis eveniet? Repellat a modi minima saepe nihil, inventore totam impedit magni reiciendis, similique quas, quos pariatur. Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim reprehenderit magni necessitatibus architecto facilis eveniet? Repellat a modi minima saepe nihil, inventore totam impedit magni reiciendis, similique quas, quos pariatur.</p>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>
