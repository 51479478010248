<template>
  <div>
    <!--  -->
  
    <div class="row">
      <div class="col-12">
        <h2>Charts - Not Interested</h2>
      </div>
    </div>
    
    <Loader :loaderShow="loaderShow"/>

    <Feedback :fbWrapperShow="fbWrapperShow" :fbDiv="fbDiv" :fbMsg="fbMsg"/>
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form class="row g-3">
              <div class="col-auto">
                  <label for="quickActions">Quick actions</label>
                  <select @change="onChange($event)" class="form-select" id="quickActions" v-model="quickActions">
                    <option value="1">Today</option>
                    <option value="2">Yesterday</option>
                    <option value="3">Last 7 Days</option>
                    <option value="4">Last 30 Days</option>
                  </select>
                </div>
              <div class="col-auto">
                <label for="fromDate">From Date*</label>
                <input type="date" class="form-control" id="fromDate" v-model="fromDate" required/> 
              </div>
              <div class="col-auto">
                <label for="toDate">To Date*</label>
                <input type="date" class="form-control" id="toDate" v-model="toDate" required/> 
              </div>
              <div class="col-auto">
                <label for="">&nbsp;</label>
                <button type="submit" @click=getChart class="btn btn-primary form-control" :disabled="buttonDisabled">Get Chart</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    
    <div v-if="chartShow">
      <div class="row mt-3">
        <div class="col-12 col-lg-6" v-if="categoriesChartShow">
          <div class="card">
            <div class="card-header">Categories</div>
            <div class="card-body">
              <div class="chart-wrapper-300">
                <Pie :data="categoriesChartData" :options="categoriesChartOptions" />
              </div>
              <div class="table-responsive mt-3">
                <table class="table table-bordered table-sm text-center table-light">
                  <tbody>
                    <tr>
                      <th>Total</th>
                      <td v-for="(item, index) in categoriesLabels" :key="index">{{ item }}</td>
                    </tr>
                    <tr>
                      <th>{{dataCountLabel}}</th>
                      <td v-for="(item, index) in categoriesData" :key="index">{{ item }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-12" v-if="dateChartShow">
          <div class="card">
            <div class="card-header">Date</div>
            <div class="card-body">
              <div class="chart-wrapper-360">
                <Line :data="dateChartData" :options="dateChartOptions" />
              </div>
              <div class="table-responsive mt-3">
                <table class="table table-bordered table-sm text-center table-light">
                  <tbody>
                    <tr>
                      <th>Total</th>
                      <th>Average</th>
                      <td v-for="(item, index) in dateLabels" :key="index">{{ item }}</td>
                    </tr>
                    <tr>
                      <th>{{dataCountLabel}}</th>
                      <th>{{dataAvarageCountLabel}}</th>
                      <td v-for="(item, index) in dateData" :key="index">{{ item }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-12" v-if="notInterestedReasonChartShow">
          <div class="card">
            <div class="card-header">Not Interested Reasons</div>
            <div class="card-body">
              <div class="chart-wrapper-360">
                <Bar :data="notInterestedReasonChartData" :options="notInterestedReasonChartOptions" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-12" v-if="notInterestedAuthorChartShow">
          <div class="card">
            <div class="card-header">Not Interested Authors</div>
            <div class="card-body">
              <div class="chart-wrapper-360">
                <Bar :data="notInterestedAuthorChartData" :options="notInterestedAuthorChartOptions" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>
  
<script>

import { CommonServices } from "@/services/CommonServices";
import Loader from "@/components/Loader.vue";
import Feedback from "@/components/Feedback.vue";

import axios from "axios";
import { GET_REPORTS_NOT_INTERESTED } from "@/services/ApiConstants";
import { Pie, Line, Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement, PointElement, LineElement, BarElement } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement, PointElement, LineElement, BarElement)


export default ({
  components: {
    Feedback,
    Loader,
    Pie,
    Line,
    Bar,
  },
  data() {
      const todayDate = new Date().toISOString().split('T')[0];
      return {
        loaderShow: true,
        fbWrapperShow: false,
        fbDiv: '',
        fbMsg: '',
        fromDate: todayDate,
        toDate: todayDate,
        chartShow: false,
        buttonDisabled: false,
        dataCountLabel: '',
        dataAvarageDaysCountLabel: '',
        dataAvarageCountLabel: '',
        quickActions: '1',
        categoriesChartShow: false,
        dateChartShow: false,
        notInterestedReasonChartShow: false,
        notInterestedAuthorChartShow: false,
        categoriesChartOptions: {
          responsive: true,
          plugins: {
            tooltip: {
              callbacks: {
                  label: function (context) {
                      const label = context.label || '';
                      const value = context.raw || '';
                      const percentage = ((value / context.dataset.data.reduce((a, b) => a + b)) * 100).toFixed(2);
                      return `${label}: ${value} (${percentage}%)`;
                  }
              }
            }
          }
        },
        dateChartOptions: {
          responsive: true,
          plugins: {
            tooltip: {
              callbacks: {
                  label: function (context) {
                      const label = context.label || '';
                      const value = context.raw || '';
                      const percentage = ((value / context.dataset.data.reduce((a, b) => a + b)) * 100).toFixed(2);
                      return `${label}: ${value} (${percentage}%)`;
                  }
              }
            }
          }
        },
        notInterestedReasonChartOptions: {
          responsive: true,
          plugins: {
            tooltip: {
              callbacks: {
                  label: function (context) {
                      const label = context.label || '';
                      const value = context.raw || '';
                      const percentage = ((value / context.dataset.data.reduce((a, b) => a + b)) * 100).toFixed(2);
                      return `${label}: ${value} (${percentage}%)`;
                  }
              }
            }
          }
        },
        notInterestedAuthorChartOptions: {
          responsive: true,
          plugins: {
            tooltip: {
              callbacks: {
                  label: function (context) {
                      const label = context.label || '';
                      const value = context.raw || '';
                      const percentage = ((value / context.dataset.data.reduce((a, b) => a + b)) * 100).toFixed(2);
                      return `${label}: ${value} (${percentage}%)`;
                  }
              }
            }
          }
        },
        categoriesChartData: [],
        categoriesLabels: [],
        categoriesData: [],
        dateChartData: [],
        dateLabels: [],
        dateData: [],
        notInterestedReasonChartData: [],
        notInterestedReasonLabels: [],
        notInterestedReasonData: [],
        notInterestedAuthorChartData: [],
        notInterestedAuthorLabels: [],
        notInterestedAuthorData: [],
      }
    },
    methods: {
      onChange(event) {
          const val = event.target.value;
          if (val === '1') {
              this.fromDate = new Date().toISOString().split('T')[0];
              this.toDate = new Date().toISOString().split('T')[0];
          } else if (val === '2') {
              const today = new Date();
              const yesterday = new Date();
              yesterday.setDate(today.getDate() - 1);
              this.fromDate = yesterday.toISOString().split('T')[0];
              this.toDate = yesterday.toISOString().split('T')[0];
          } else if (val === '3') {
              const today = new Date();
              const sevendays = new Date();
              sevendays.setDate(today.getDate() - 7);
              this.fromDate = sevendays.toISOString().split('T')[0];
              this.toDate = new Date().toISOString().split('T')[0];
          } else if (val === '4') {
              const today = new Date();
              const thirtydays = new Date();
              thirtydays.setDate(today.getDate() - 30);
              this.fromDate = thirtydays.toISOString().split('T')[0];
              this.toDate = new Date().toISOString().split('T')[0];
          } else {
              this.fromDate = new Date().toISOString().split('T')[0];
              this.toDate = new Date().toISOString().split('T')[0];
          }
      },
      async getChart(e) {
        e.preventDefault();
        this.getChartData();
      },
      async getChartData() {
        this.buttonDisabled = true;
        this.loaderShow = true;
        this.chartShow = false;
        this.fbWrapperShow = false;
        this.fbDiv = '';
        this.fbMsg = '';
        this.dataCountLabel = '';
        let startMillis = new Date(this.fromDate).getTime();
        let endMillis = new Date(this.toDate).getTime();
        let differenceMillis = endMillis - startMillis;
        const differenceDays = differenceMillis / (1000 * 60 * 60 * 24);
        this.dataAvarageDaysCountLabel = Math.round(differenceDays)+1;
        let categoriesparams = "";
        for(let i = 1; i < 5; i++){
          categoriesparams += ("&category[]="+i);
        }
        let reqUrl = `${GET_REPORTS_NOT_INTERESTED}?city=1&from_date=${this.fromDate}&to_date=${this.toDate}${categoriesparams}`;
        let token = await CommonServices.getUserToken();
        axios.get(reqUrl, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          let responseData  = response.data;
          if(responseData.success) {
            const data = responseData.data;
            this.dataCountLabel = responseData.data_count;
            this.categoriesChartData = [];
            this.categoriesLabels = [];
            this.categoriesData = [];
            this.dateChartData = [];
            this.dateLabels = [];
            this.dateData = [];
            this.notInterestedReasonChartData = [];
            this.notInterestedReasonLabels = [];
            this.notInterestedReasonData = [];
            this.notInterestedAuthorChartData = [];
            this.notInterestedAuthorLabels = [];
            this.notInterestedAuthorData = [];
            for (let x in data) {
              let category = data[x].category;
              let order_not_interested = data[x].order_not_interested;
              let not_interested_date = "";
              let not_interested_reason = "";
              let not_interested_author = "";
              if(order_not_interested.length > 0) {
                for (let r1 in order_not_interested) {
                  not_interested_date = order_not_interested[r1].created_at_date;
                  not_interested_reason = order_not_interested[r1].oni_reason;
                  not_interested_author = order_not_interested[r1].creator_name.toUpperCase();
                }
              }

              if(this.categoriesLabels.includes(category)) {
                let index = this.categoriesLabels.indexOf(category);
                this.categoriesData[index] = this.categoriesData[index]+1;
              } else {
                this.categoriesLabels.push(category);
                this.categoriesData.push(1);
              }
              if(this.dateLabels.includes(not_interested_date)) {
                let index = this.dateLabels.indexOf(not_interested_date);
                this.dateData[index] = this.dateData[index]+1;
              } else {
                this.dateLabels.push(not_interested_date);
                this.dateData.push(1);
              }
              if(this.notInterestedReasonLabels.includes(not_interested_reason)) {
                let index = this.notInterestedReasonLabels.indexOf(not_interested_reason);
                this.notInterestedReasonData[index] = this.notInterestedReasonData[index]+1;
              } else {
                this.notInterestedReasonLabels.push(not_interested_reason);
                this.notInterestedReasonData.push(1);
              }
              if(this.notInterestedAuthorLabels.includes(not_interested_author)) {
                let index = this.notInterestedAuthorLabels.indexOf(not_interested_author);
                this.notInterestedAuthorData[index] = this.notInterestedAuthorData[index]+1;
              } else {
                this.notInterestedAuthorLabels.push(not_interested_author);
                this.notInterestedAuthorData.push(1);
              }
            }

            let swapA = this.dataCountLabel/this.dataAvarageDaysCountLabel;
            this.dataAvarageCountLabel = Number(swapA.toFixed(2));
            this.dateLabels.reverse();
            this.dateData.reverse();

            this.categoriesChartData = {
              labels: this.categoriesLabels,
              datasets: [
                {
                  backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                  data: this.categoriesData
                }
              ]
            };
            this.categoriesChartShow = true;

            this.dateChartData = {
              labels: this.dateLabels,
              datasets: [
                {
                  label: 'Date wise data',
                  backgroundColor: '#f87979',
                  data: this.dateData
                }
              ]
            };
            this.dateChartShow = true;

            this.notInterestedReasonChartData = {
              labels: this.notInterestedReasonLabels,
              datasets: [
                {
                  label: 'Not Interested Reasons',
                  backgroundColor: '#f87979',
                  data: this.notInterestedReasonData
                }
              ]
            };
            this.notInterestedReasonChartShow = true;

            this.notInterestedAuthorChartData = {
              labels: this.notInterestedAuthorLabels,
              datasets: [
                {
                  label: 'Not Interested Author',
                  backgroundColor: '#f87979',
                  data: this.notInterestedAuthorData
                }
              ]
            };
            this.notInterestedAuthorChartShow = true;

            this.buttonDisabled = false;
            this.loaderShow = false;
            this.chartShow = true;
            this.fbWrapperShow = false;
            this.fbDiv = '';
            this.fbMsg = '';
          } else {
            let message = "";
            if(responseData.status == "not_ok") {
              let message1 = responseData.message;
              message = "<ul>";
              for (const key in message1) {
                message += "<li>"+message1[key][0]+"</li>";
              }
              message += "</ul>";
            } else {
              message = "<div>"+responseData.message+"</div>";
            }
            this.buttonDisabled = false;
            this.loaderShow = false;
            this.chartShow = false;
            this.fbWrapperShow = true;
            this.fbDiv = 'alert-danger';
            this.fbMsg = message;
          }
        })
        .catch(error => {
          console.log("error ", error);
          console.log("error response ", error.response);
          let msg = "Server error";
          this.buttonDisabled = false;
          this.loaderShow = false;
          this.chartShow = false;
          this.fbWrapperShow = true;
          this.fbDiv = 'alert-danger';
          this.fbMsg = msg;
        });
      },
    },
    async created() {
      await this.getChartData();
      // this.loaderShow = false;
    }
})


</script>