<template>
  <div>
    <!--  -->

    <div class="row">
      <div class="col-9">
        <h2>Vendors</h2>
      </div>
      <div class="col-3">
        <router-link to="/vendors-create" class="btn btn-primary btn-sm float-end">Add</router-link>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4>Coming soon...!</h4>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>