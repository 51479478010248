<template>
  <div>
    <!--  -->
  
    <div class="row">
      <div class="col-12">
        <h2>Reports - Completed Orders</h2>
      </div>
    </div>
  
  <Loader :loaderShow="loaderShow"/>

  <Feedback :fbWrapperShow="fbWrapperShow" :fbDiv="fbDiv" :fbMsg="fbMsg"/>
  
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3">
            <div class="col-auto">
              <label for="fromDate">From Date*</label>
              <input type="date" class="form-control" id="fromDate" v-model="fromDate" required/> 
            </div>
            <div class="col-auto">
              <label for="toDate">To Date*</label>
              <input type="date" class="form-control" id="toDate" v-model="toDate" required/> 
            </div>
            <div class="col-auto">
              <label for="categories">Categories*</label>
              <select class="form-select" id="categories" v-model="selectedCategories" multiple required>
                <option value="selectAll" :disabled="selectAllDisabled">Select All</option>
                <option v-for="item in categories" :value="item.category_id" :key="item.id">{{item.category_title}}</option>
              </select>
            </div>
            <div class="col-auto">
              <label for="">&nbsp;</label>
              <button type="submit" @click=getReport class="btn btn-primary form-control" :disabled="buttonDisabled">Get Report</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row mt-3" v-if="tableRowShow">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="mb-3">
            <button type="button" @click=exportToExcel class="btn btn-primary btn-sm">Export to Excel <span class="badge bg-light text-dark ms-1" v-if="dataCountLabel !== ''">{{dataCountLabel}}</span></button>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-sm" id="completedOrdersTable">
              <thead class="table-secondary">
                <tr>
                  <th>Order ID</th>
                  <th>Complete Date</th>
                  <th>Month</th>
                  <th>Category</th>
                  <th>Brand</th>
                  <th>Model/Segment</th>
                  <th>Issue</th>
                  <th>Issue Price</th>
                  <th>Parts Price</th>
                  <th>Extra Charges Price</th>
                  <th>Discount</th>
                  <th>Coupon</th>
                  <th>Sub Total</th>
                  <th>Convience Fee</th>
                  <th>Grand Total</th>
                  <th>Payment Method</th>
                  <th>Confirmed By</th>
                  <th>Confirmed At</th>
                  <th>Customer Name</th>
                  <th>Customer Mobile</th>
                  <th>Revenue Group</th>
                  <th>Partner Name</th>
                  <th>Partner Mobile</th>
                  <th>Lead Cost</th>
                  <th>Commision</th>
                  <th>Convinence Fee Px</th>
                  <th>Convience Fee Cx</th>
                  <th>Payment Gateway Charges</th>
                  <th>Partner Earning before TDS</th>
                  <th>TDS 2%</th>
                  <th>Partner Earning after TDS</th>
                  <th>AFPL Earning with GST</th>
                  <th>GST</th>
                  <th>AFPL Earning</th>
                </tr>
              </thead>
              <tbody>
                <!-- tableRowData -->
                <tr v-for="item in tableRowData" :key="item.id">
                  <td>{{ item.order_id }}</td>
                  <td>{{ item.completed_date }}</td>
                  <td>{{ item.completed_month }}</td>
                  <td>{{ item.category }}</td>
                  <td>{{ item.brand }}</td>
                  <td>{{ item.model_segment }}</td>
                  <td>{{ item.issues }}</td>
                  <td class="text-end">{{ item.issues_total_price }}</td>
                  <td class="text-end">{{ item.parts_price }}</td>
                  <td class="text-end">{{ item.extra_charges_price }}</td>
                  <td class="text-end">{{ item.discounts }}</td>
                  <td class="text-end">{{ item.coupons }}</td>
                  <td class="text-end">{{ item.sub_total }}</td>
                  <td class="text-end">{{ item.convinence_fee }}</td>
                  <td class="text-end table-warning">{{ item.order_final_price }}</td>
                  <td>{{ item.payment_mode }}</td>
                  <td>{{ item.confirmed_by }}</td>
                  <td>{{ item.confirmed_at }}</td>
                  <td>{{ item.user_fullname }}</td>
                  <td>{{ item.user_mobile }}</td>
                  <td>{{ item.revenue_group_title }}</td>
                  <td>{{ item.partner_name }}</td>
                  <td>{{ item.partner_mobile }}</td>
                  <td class="text-end">{{ item.lead_charge }}</td>
                  <td class="text-end">{{ item.commission }}</td>
                  <td class="text-end">{{ item.convinence_fee_px }}</td>
                  <td class="text-end">{{ item.convinence_fee_cx }}</td>
                  <td class="text-end">{{ item.payment_gateway_charges }}</td>
                  <td class="text-end">{{ item.partner_earning_before_tds }}</td>
                  <td class="text-end">{{ item.tds }}</td>
                  <td class="text-end">{{ item.partner_earning_after_tds }}</td>
                  <td class="text-end">{{ item.earnings_with_gst }}</td>
                  <td class="text-end">{{ item.gst }}</td>
                  <td class="text-end table-warning">{{ item.earnings }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

    <!--  -->
  </div>
</template>

<script>

import { CommonServices } from "@/services/CommonServices";
import Loader from "@/components/Loader.vue";
import Feedback from "@/components/Feedback.vue";

import * as XLSX from "xlsx";
import axios from "axios";
import { GET_REPORTS_COMPLETED_ORDERS } from "@/services/ApiConstants";

export default ({
  components: {
    Feedback,
    Loader,
  },
  data() {
      return {
        loaderShow: true,
        fbWrapperShow: false,
        fbDiv: '',
        fbMsg: '',
        fromDate: '',
        toDate: '',
        categories: [],
        selectedCategories: [],
        tableRowShow: false,
        tableRowData: [],
        buttonDisabled: false,
        dataCountLabel: '',
        selectAllDisabled: true,
      }
    },
    watch: {
      selectedCategories(newValues) {
        if (newValues.includes('selectAll')) {
          this.selectedCategories = this.categories.map(categories => categories.category_id);
        }
    }
  },
    methods: {
      async fetchCategories() {
        let response = await CommonServices.getAllCategories();
        if(response.success) {
          this.categories = response.data;
        }
        if(this.categories.length > 1) {
          this.selectAllDisabled = false;
        }
      },
      async getReport(e) {
        e.preventDefault();
        this.buttonDisabled = true;
        this.loaderShow = true;
        this.tableRowShow = false;
        this.fbWrapperShow = false;
        this.fbDiv = '';
        this.fbMsg = '';
        this.dataCountLabel = '';
        let categoriesparams = "";
        for(let i = 0; i < this.selectedCategories.length; i++){
          categoriesparams += ("&category[]="+this.selectedCategories[i]);
        }
        let reqUrl = `${GET_REPORTS_COMPLETED_ORDERS}?city=1&from_date=${this.fromDate}&to_date=${this.toDate}${categoriesparams}`;
        let token = await CommonServices.getUserToken();
        axios.get(reqUrl, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          let responseData  = response.data;
          if(responseData.success) {
            const data = responseData.data;
            this.dataCountLabel = responseData.data_count;
            let monthNames = [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December'
            ];
            const dateFormatOptions = {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              timeZone: 'Asia/Kolkata'
            };
            this.tableRowData = [];
            let grandTotal_issues_total_price = 0;
            let grandTotal_parts_price = 0;
            let grandTotal_extra_charges_price = 0;
            let grandTotal_discounts = 0;
            let grandTotal_coupons = 0;
            let grandTotal_sub_total = 0;
            let grandTotal_convinence_fee = 0;
            let grandTotal_order_final_price = 0;
            let grandTotal_lead_charge = 0;
            let grandTotal_commission = 0;
            let grandTotal_convinence_fee_px = 0;
            let grandTotal_convinence_fee_cx = 0;
            let grandTotal_payment_gateway_charges = 0;
            let grandTotal_partner_earning_before_tds = 0;
            let grandTotal_tds = 0;
            let grandTotal_partner_earning_after_tds = 0;
            let grandTotal_earnings_with_gst = 0;
            let grandTotal_gst = 0;
            let grandTotal_earnings = 0;
            for (let x in data) {
              let order_id = data[x].order_id;
              let user_fullname = data[x].user_fullname.toUpperCase();
              let user_mobile = data[x].user_mobile;
              let category = data[x].category;
              let brand = data[x].brand;
              let model_segment = data[x].model_segment;
              let order_issues = data[x].order_issues;
              let order_parts = data[x].order_parts;
              let order_extra_charges = data[x].order_extra_charges;
              let order_discounts_emp_data = data[x].order_discounts_emp_data;
              let order_promo_codes_data = data[x].order_promo_codes_data;
              let order_fee_taxs = data[x].order_fee_taxs;
              let order_payments = data[x].order_payments;
              let order_final_price = data[x].order_final_price;
              let order_confirmed_timestamp = data[x].order_confirmed_timestamp;
              let order_completed_timestamp = data[x].order_completed_timestamp;
              let order_for_aggregator = data[x].order_for_aggregator;
              let order_for_inhouse = data[x].order_for_inhouse;
              let order_revenue_group_title = data[x].order_revenue_group_title;
              let order_partners_completed = data[x].order_partners_completed;
              let order_pw_details = data[x].order_pw_details;
              let order_earning_details = data[x].order_earning_details;

              const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

              let confirmed_by = "";
              let confirmed_at = "";
              if(order_confirmed_timestamp.length > 0) {
                for (let r0 in order_confirmed_timestamp) {
                  confirmed_by = order_confirmed_timestamp[r0].creator_fullname.toUpperCase();
                  confirmed_at = order_confirmed_timestamp[r0].created_at;
                }
              }

              let completed_date = "";
              let completed_month = "";
              if(order_completed_timestamp.length > 0) {
                for (let r1 in order_completed_timestamp) {
                  completed_date = order_completed_timestamp[r1].created_at_date;
                  const date = new Date(completed_date);
                  completed_month = monthNames[date.getMonth()];
                  completed_date = date.toLocaleDateString('en-IN', dateFormatOptions);
                }
              }

              let issues = "";
              let issues_total_price = 0;
              if (order_issues.length > 0) {
                for (let r2 in order_issues) {
                  issues += order_issues[r2].issue_name+", ";
                  issues_total_price += parseFloat(order_issues[r2].issue_discounted_price);
                }
                issues = issues.slice(0, -2);
              }
              issues_total_price = issues_total_price.toFixed(2);

              let parts_price = 0;
              if (order_parts.length > 0) {
                for (let r3 in order_parts) {
                  parts_price += parseFloat(order_parts[r3].part_price);
                }
              }
              parts_price = parts_price.toFixed(2);

              let extra_charges_price = 0;
              if (order_extra_charges.length > 0) {
                for (let r4 in order_extra_charges) {
                  extra_charges_price += parseFloat(order_extra_charges[r4].charge_price);
                }
              }
              extra_charges_price = extra_charges_price.toFixed(2);

              let discounts = 0;
              if (order_discounts_emp_data.length > 0) {
                for (let r5 in order_discounts_emp_data) {
                  discounts += parseFloat(order_discounts_emp_data[r5].discount_price);
                }
              }
              discounts = discounts.toFixed(2);

              let coupons = 0;
              if (order_promo_codes_data.length > 0) {
                for (let r6 in order_promo_codes_data) {
                  coupons += parseFloat(order_promo_codes_data[r6].pc_amount);
                }
              }
              coupons = coupons.toFixed(2);
              
              let sub_total = parseFloat(issues_total_price) + parseFloat(parts_price) + parseFloat(extra_charges_price)-parseFloat(discounts)-parseFloat(coupons);
              sub_total = sub_total.toFixed(2);

              let convinence_fee = 0;
              if (order_fee_taxs.length > 0) {
                for (let r7 in order_fee_taxs) {
                  convinence_fee = order_fee_taxs[r7].ocf_amount;
                }
              }
              convinence_fee = parseFloat(convinence_fee).toFixed(2);

              order_final_price = order_final_price.toFixed(2);

              let payment_mode = "";
              if (order_payments.length > 0) {
                for (let r8 in order_payments) {
                  payment_mode = capitalizeFirstLetter(order_payments[r8].payment_type);
                }
              }

              let partner_name = "";
              let partner_mobile = "";
              if (order_partners_completed.length > 0) {
                for (let r9 in order_partners_completed) {
                  partner_name = order_partners_completed[r9].partner_fullname.toUpperCase();
                  partner_mobile = order_partners_completed[r9].partner_mobile;
                }
              }

              order_revenue_group_title = order_revenue_group_title.toUpperCase();

              let lead_charge = 0;
              let commission = 0;
              let convinence_fee_px = 0;
              let convinence_fee_cx = 0;
              let payment_gateway_charges = 0;
              let tds = 0;
              let partner_earning_before_tds = 0;
              let partner_earning_after_tds = 0;
              let earnings_with_gst = 0;
              let gst = 0;
              let earnings = 0;
              if (order_for_aggregator) {
                if (order_pw_details.length > 0) {
                  let partner_wallet_deductions = 0;
                  for (let r9 in order_pw_details) {
                    let label = order_pw_details[r9].label;
                    let amount = order_pw_details[r9].amount;
                    partner_wallet_deductions += parseFloat(amount);
                    if (label == "Lead Charges") {
                      lead_charge += parseFloat(amount);
                    }
                    if (label == "Commission") {
                      commission += parseFloat(amount);
                    }
                    if (label == "Convenience Fee") {
                      convinence_fee_px += parseFloat(amount);
                    }
                    if (label == "Convenience Fee Cx") {
                      convinence_fee_cx += parseFloat(amount);
                    }
                    if (label == "Payment Gateway Charges") {
                      payment_gateway_charges += parseFloat(amount);
                    }
                    if (label == "Tds") {
                      tds += parseFloat(amount);
                    }
                  }
                  partner_wallet_deductions = partner_wallet_deductions.toFixed(2);
                  partner_earning_before_tds = parseFloat(order_final_price)-parseFloat(partner_wallet_deductions)+parseFloat(tds);
                  partner_earning_after_tds = parseFloat(order_final_price)-parseFloat(partner_wallet_deductions);
                  earnings_with_gst = parseFloat(partner_wallet_deductions)-parseFloat(tds);
                  gst = parseFloat(earnings_with_gst/118*18);
                  earnings = parseFloat(earnings_with_gst-gst);
                }
              }
              if(order_for_inhouse) {
                if (order_earning_details.length > 0) {
                  for (let r10 in order_earning_details) {
                    earnings_with_gst = order_earning_details[r10].total;
                    gst = order_earning_details[r10].gst;
                    earnings = order_earning_details[r10].without_gst;
                  }
                }
              }
              lead_charge = lead_charge.toFixed(2);
              commission = commission.toFixed(2);
              convinence_fee_px = convinence_fee_px.toFixed(2);
              convinence_fee_cx = convinence_fee_cx.toFixed(2);
              payment_gateway_charges = payment_gateway_charges.toFixed(2);
              tds = tds.toFixed(2);
              partner_earning_before_tds = partner_earning_before_tds.toFixed(2);
              partner_earning_after_tds = partner_earning_after_tds.toFixed(2);
              earnings_with_gst = parseFloat(earnings_with_gst).toFixed(2);
              gst = parseFloat(gst).toFixed(2);
              earnings = parseFloat(earnings).toFixed(2);

              // total row data - adding
              grandTotal_issues_total_price += parseFloat(issues_total_price);
              grandTotal_parts_price += parseFloat(parts_price);
              grandTotal_extra_charges_price += parseFloat(extra_charges_price);
              grandTotal_discounts += parseFloat(discounts);
              grandTotal_coupons += parseFloat(coupons);
              grandTotal_sub_total += parseFloat(sub_total);
              grandTotal_convinence_fee += parseFloat(convinence_fee);
              grandTotal_order_final_price += parseFloat(order_final_price);
              grandTotal_lead_charge += parseFloat(lead_charge);
              grandTotal_commission += parseFloat(commission);
              grandTotal_convinence_fee_px += parseFloat(convinence_fee_px);
              grandTotal_convinence_fee_cx += parseFloat(convinence_fee_cx);
              grandTotal_payment_gateway_charges += parseFloat(payment_gateway_charges);
              grandTotal_partner_earning_before_tds += parseFloat(partner_earning_before_tds);
              grandTotal_tds += parseFloat(tds);
              grandTotal_partner_earning_after_tds += parseFloat(partner_earning_after_tds);
              grandTotal_earnings_with_gst += parseFloat(earnings_with_gst);
              grandTotal_gst += parseFloat(gst);
              grandTotal_earnings += parseFloat(earnings);

              // 
              this.tableRowData = [...this.tableRowData, {
                "order_id" : order_id,
                "completed_date" : completed_date,
                "completed_month" : completed_month,
                "category" : category,
                "brand" : brand,
                "model_segment" : model_segment,
                "issues" : issues,
                "issues_total_price" : issues_total_price,
                "parts_price" : parts_price,
                "extra_charges_price" : extra_charges_price,
                "discounts" : discounts,
                "coupons" : coupons,
                "sub_total" : sub_total,
                "convinence_fee" : convinence_fee,
                "order_final_price" : order_final_price,
                "payment_mode" : payment_mode,
                "confirmed_by" : confirmed_by,
                "confirmed_at" : confirmed_at,
                "user_fullname" : user_fullname,
                "user_mobile" : user_mobile,
                "partner_name" : partner_name,
                "revenue_group_title" : order_revenue_group_title,
                "partner_mobile" : partner_mobile,
                "lead_charge" : lead_charge,
                "commission" : commission,
                "convinence_fee_px" : convinence_fee_px,
                "convinence_fee_cx" : convinence_fee_cx,
                "payment_gateway_charges" : payment_gateway_charges,
                "partner_earning_before_tds" : partner_earning_before_tds,
                "tds" : tds,
                "partner_earning_after_tds" : partner_earning_after_tds,
                "earnings_with_gst" : earnings_with_gst,
                "gst" : gst,
                "earnings" : earnings,
              }];
            }
            // total row data - formatting
            grandTotal_issues_total_price = grandTotal_issues_total_price.toFixed(2);
            grandTotal_parts_price = grandTotal_parts_price.toFixed(2);
            grandTotal_extra_charges_price = grandTotal_extra_charges_price.toFixed(2);
            grandTotal_discounts = grandTotal_discounts.toFixed(2);
            grandTotal_coupons = grandTotal_coupons.toFixed(2);
            grandTotal_sub_total = grandTotal_sub_total.toFixed(2);
            grandTotal_convinence_fee = grandTotal_convinence_fee.toFixed(2);
            grandTotal_order_final_price = grandTotal_order_final_price.toFixed(2);
            grandTotal_lead_charge = grandTotal_lead_charge.toFixed(2);
            grandTotal_commission = grandTotal_commission.toFixed(2);
            grandTotal_convinence_fee_px = grandTotal_convinence_fee_px.toFixed(2);
            grandTotal_convinence_fee_cx = grandTotal_convinence_fee_cx.toFixed(2);
            grandTotal_payment_gateway_charges = grandTotal_payment_gateway_charges.toFixed(2);
            grandTotal_partner_earning_before_tds = grandTotal_partner_earning_before_tds.toFixed(2);
            grandTotal_tds = grandTotal_tds.toFixed(2);
            grandTotal_partner_earning_after_tds = grandTotal_partner_earning_after_tds.toFixed(2);
            grandTotal_earnings_with_gst = grandTotal_earnings_with_gst.toFixed(2);
            grandTotal_gst = grandTotal_gst.toFixed(2);
            grandTotal_earnings = grandTotal_earnings.toFixed(2);
            // total row
            this.tableRowData = [...this.tableRowData, {
              "order_id" : "",
              "completed_date" : "",
              "completed_month" : "",
              "category" : "",
              "brand" : "",
              "model_segment" : "",
              "issues" : "",
              "issues_total_price" : grandTotal_issues_total_price,
              "parts_price" : grandTotal_parts_price,
              "extra_charges_price" : grandTotal_extra_charges_price,
              "discounts" : grandTotal_discounts,
              "coupons" : grandTotal_coupons,
              "sub_total" : grandTotal_sub_total,
              "convinence_fee" : grandTotal_convinence_fee,
              "order_final_price" : grandTotal_order_final_price,
              "payment_mode" : "",
              "confirmed_by" : "",
              "confirmed_at" : "",
              "user_fullname" : "",
              "user_mobile" : "",
              "partner_name" : "",
              "revenue_group_title" : "",
              "partner_mobile" : "",
              "lead_charge" : grandTotal_lead_charge,
              "commission" : grandTotal_commission,
              "convinence_fee_px" : grandTotal_convinence_fee_px,
              "convinence_fee_cx" : grandTotal_convinence_fee_cx,
              "payment_gateway_charges" : grandTotal_payment_gateway_charges,
              "partner_earning_before_tds" : grandTotal_partner_earning_before_tds,
              "tds" : grandTotal_tds,
              "partner_earning_after_tds" : grandTotal_partner_earning_after_tds,
              "earnings_with_gst" : grandTotal_earnings_with_gst,
              "gst" : grandTotal_gst,
              "earnings" : grandTotal_earnings,
            }];
            this.buttonDisabled = false;
            this.loaderShow = false;
            this.tableRowShow = true;
            this.fbWrapperShow = false;
            this.fbDiv = '';
            this.fbMsg = '';
          } else {
            let message = "";
            if(responseData.status == "not_ok") {
              let message1 = responseData.message;
              message = "<ul>";
              for (const key in message1) {
                message += "<li>"+message1[key][0]+"</li>";
              }
              message += "</ul>";
            } else {
              message = "<div>"+responseData.message+"</div>";
            }
            this.buttonDisabled = false;
            this.loaderShow = false;
            this.tableRowShow = false;
            this.fbWrapperShow = true;
            this.fbDiv = 'alert-danger';
            this.fbMsg = message;
          }
        })
        .catch(error => {
          // let response = JSON.parse(error.request.response);
          // let msg = response.message;
          console.log("error ", error);
          console.log("error response ", error.response);
          let msg = "Server error";
          this.buttonDisabled = false;
          this.loaderShow = false;
          this.tableRowShow = false;
          this.fbWrapperShow = true;
          this.fbDiv = 'alert-danger';
          this.fbMsg = msg;
        });
      },
      exportToExcel(e) {
        e.preventDefault();
        const timestamp = Date.now();
        const table = document.querySelector('#completedOrdersTable')
        const worksheet = XLSX.utils.table_to_sheet(table)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        let fileName = "myData_"+timestamp+".xlsx"; 
        if(this.fromDate != "" && this.toDate != "") {
          fileName = "AFPL_completed_orders_"+this.fromDate+"_to_"+this.toDate+"_"+timestamp+".xlsx";
        } else {
          fileName = "AFPL_completed_orders_"+timestamp+".xlsx";
        }
        XLSX.writeFile(workbook, fileName)
      },
    },
    async created(){
      await this.fetchCategories();
      this.loaderShow = false;
    }
  })

</script>