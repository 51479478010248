import axios from "axios";
import {
  GET_CATEGORIES_BY_CITIES,
  GET_CITIES,
  GET_PARTNERS,
} from "@/services/ApiConstants";

export class CommonServices {
  // --------------------------------------------------
  static getUserToken() {
    return localStorage.getItem("token");
    // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBfaWQiOjEsImVtcF9tb2JpbGUiOiI5NjIwNzA3ODI4IiwidG9rZW5fY3JlYXRlZF9hdCI6MTY5MjYxOTMzMiwidG9rZW5fZXhwaXJlc19hdCI6MTY5NDM0NzMzMn0.RM7vsEaWgtMZZnWDhcKIi_KxEbPbeMHOXOW4TPqe2Xg";
    // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBfaWQiOjEsImVtcF9tb2JpbGUiOiI5NjIwNzA3ODI4IiwidG9rZW5fY3JlYXRlZF9hdCI6MTY5MzQwOTYxOCwidG9rZW5fZXhwaXJlc19hdCI6MTY5NTEzNzYxOH0.0a_my53BLG1MroMA4mmr7zIVRogIRJe9W3R8k1PNPeU";
  }

  // --------------------------------------------------
  static removeUserToken() {
    if (localStorage.getItem("token") != "") {
      localStorage.removeItem("token", "");
    }
    return true;
  }

  // --------------------------------------------------
  static getUserDetails() {
    return {
      token: localStorage.getItem("token"),
      emp_id: localStorage.getItem("emp_id"),
      emp_fullname: localStorage.getItem("emp_fullname"),
      emp_mobile: localStorage.getItem("emp_mobile"),
      emp_email: localStorage.getItem("emp_email"),
    };
  }

  // --------------------------------------------------
  static removeUserDetails() {
    if (localStorage.getItem("token") != "") {
      localStorage.removeItem("token", "");
    }
    if (localStorage.getItem("emp_id") != "") {
      localStorage.removeItem("emp_id", "");
    }
    if (localStorage.getItem("emp_fullname") != "") {
      localStorage.removeItem("emp_fullname", "");
    }
    if (localStorage.getItem("emp_mobile") != "") {
      localStorage.removeItem("emp_mobile", "");
    }
    if (localStorage.getItem("emp_email") != "") {
      localStorage.removeItem("emp_email", "");
    }
    return true;
  }

  // --------------------------------------------------
  static getAllCategories() {
    let reqUrl = `${GET_CATEGORIES_BY_CITIES}?city=1`;
    return axios
      .get(reqUrl)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  // --------------------------------------------------
  static getCities() {
    let reqUrl = `${GET_CITIES}`;
    return axios
      .get(reqUrl)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  // --------------------------------------------------
  static getAllPartners() {
    let reqUrl = `${GET_PARTNERS}`;
    let token = this.getUserToken();
    return axios
      .get(reqUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  // --------------------------------------------------
  // --------------------------------------------------
  // --------------------------------------------------

  // this.partners = this.partners.map(partners => partners.partner_fullname.toUpperCase());
  // console.log('herer');
  // console.log(this.partners);
  // <!-- item.partner_fullname = this.isUpperCase ? item.name.toUpperCase() : item.name.toLowerCase(); -->

  // return response.data;
  // return error.response.data;

  // console.log('response');
  // console.log(response);
  // let responseData = response.data;
  // console.log('responseData');
  // console.log(responseData);
  // if(responseData.success) {
  //   this.partners = responseData.data;
  // }

  // async function getAllPartners() {
  // let reqUrl = `${GET_CITIES}`;
  // let reqUrl = `${GET_CATEGORIES_BY_CITIES}?city=1`;
  // return axios.get(reqUrl);

  // return getData = async () => {
  //   // const response = await axios.get(reqUrl);
  //   await axios.get(reqUrl);
  // };
  // return getData;

  // return await axios.get(reqUrl);
  // let res = "";
  // axios
  //   .get(reqUrl)
  //   .then((response) => {
  //     return response.data;
  //     // res = response.data;
  //     // response.data;
  //     // console.log("response.data");
  //     // console.log(response.data);
  //     // let responseData = response.data;
  //     // if (responseData.success) {
  //     //   this.categories = responseData.data;
  //     // }
  //   })
  //   .catch((error) => {
  //     return error.response.data;
  //     // res = error.response.data;
  //     // error.response.data;
  //     // console.error(error);
  //     // return [];
  //     // console.log("error.data");
  //     // console.log(error.message);
  //     // console.log(error.response);
  //     // console.log(error.response.status);
  //     // console.log(error.response.statusText);
  //     // console.log(error.response.data);
  //     // return [error];
  //   });
  // console.log("res");
  // console.log(res);

  // axios
  //   .get(reqUrl)
  //   .then((response) => {
  //     let responseData = response.data;
  //     if (responseData.success) {
  //       this.categories = responseData.data;
  //     }
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });

  // let reqUrl = "https://www.eripbee.in/api/v1/categories_by_cities?city=1";
  // axios.get(reqUrl)
  // .then(response => {
  //   let responseData = response.data;
  //   if(responseData.success) {
  //     this.categories = responseData.data;
  //   }
  // })
  // .catch(error => {
  //   console.error(error);
  // });

  // let token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBfaWQiOjEsImVtcF9tb2JpbGUiOiI5NjIwNzA3ODI4IiwidG9rZW5fY3JlYXRlZF9hdCI6MTY5MjU5NTA5NywidG9rZW5fZXhwaXJlc19hdCI6MTY5NDMyMzA5N30.QCy3FU6CBa-rec25Re-ogVMgr_aLF03h1gNsfDyBA0U`;
  // let token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBfaWQiOjEsImVtcF9tb2JpbGUiOiI5NjIwNzA3ODI4IiwidG9rZW5fY3JlYXRlZF9hdCI6MTY5MjYxOTMzMiwidG9rZW5fZXhwaXJlc19hdCI6MTY5NDM0NzMzMn0.RM7vsEaWgtMZZnWDhcKIi_KxEbPbeMHOXOW4TPqe2Xg`;
  // --------------------------------------------------
  // --------------------------------------------------
  // --------------------------------------------------
}
