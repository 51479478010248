import { createRouter, createWebHistory } from "vue-router";

import { AuthGuardAlreadyLogged } from "../services/AuthGuardAlreadyLogged";
import { AuthGuard } from "../services/AuthGuard";

import AuthLayout from "../layouts/AuthLayout.vue";
import AppLayout from "../layouts/AppLayout.vue";
import Page404Layout from "../layouts/Page404Layout.vue";

import AboutView from "../views/static/AboutView";
import HelpView from "../views/static/HelpView";
import LoginView from "../views/auth/LoginView.vue";
import LogoutView from "../views/auth/LogoutView.vue";
import MyProfileView from "../views/MyProfileView.vue";
import DashboardView from "../views/DashboardView.vue";

import VendorsCreateView from "../views/vendors/VendorsCreateView.vue";
import VendorsReadView from "../views/vendors/VendorsReadView.vue";

import ReportsAllDataView from "../views/reports/AllDataView.vue";
import ReportsNotInterestedView from "../views/reports/NotInterestedView.vue";
import ReportsCompleatedOrdersView from "../views/reports/CompleatedOrdersView.vue";
import ReportsCancelledOrdersView from "../views/reports/CancelledOrdersView.vue";
import ReportsPartnerWalletView from "../views/reports/PartnerWalletView.vue";

import ChartsAllDataView from "../views/charts/AllDataView.vue";
import ChartsNotInterestedView from "../views/charts/NotInterestedView.vue";
import ChartsCompleatedOrdersView from "@/views/charts/CompleatedOrdersView.vue";
import ChartsCancelledOrdersView from "../views/charts/CancelledOrdersView.vue";

import RmsRegistrationView from "../views/rms/RegistrationView.vue";

const routes = [
  // {
  //   path: "/:catchAll(.*)",
  //   redirect: { name: "dashboard" },
  // },
  {
    path: "/logout",
    component: AppLayout,
    children: [
      {
        name: "logout",
        path: "",
        component: LogoutView,
      },
    ],
  },
  {
    path: "/",
    beforeEnter: AuthGuardAlreadyLogged,
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "login",
        component: LoginView,
      },
      {
        path: "",
        redirect: { name: "login" },
      },
    ],
  },
  {
    path: "/",
    beforeEnter: AuthGuard,
    component: AppLayout,
    children: [
      // STATIC
      {
        name: "about",
        path: "about",
        component: AboutView,
      },
      {
        name: "help",
        path: "help",
        component: HelpView,
      },
      // USER BASED
      {
        name: "my_profile",
        path: "my-profile",
        component: MyProfileView,
      },
      {
        name: "dashboard",
        path: "dashboard",
        component: DashboardView,
        meta: {
          title: "Dashboard",
        },
      },
      // VENDORS
      {
        name: "vendors_create",
        path: "vendors-create",
        component: VendorsCreateView,
      },
      {
        name: "vendors",
        path: "vendors",
        component: VendorsReadView,
      },
      // REPORTS
      {
        name: "reportsalldata",
        path: "reportsalldata",
        component: ReportsAllDataView,
      },
      {
        name: "reportsnotinterested",
        path: "reportsnotinterested",
        component: ReportsNotInterestedView,
      },
      {
        name: "reportscompleatedorders",
        path: "reportscompleatedorders",
        component: ReportsCompleatedOrdersView,
      },
      {
        name: "reportscancelledorders",
        path: "reportscancelledorders",
        component: ReportsCancelledOrdersView,
      },
      {
        name: "reportspartnerwallet",
        path: "reportspartnerwallet",
        component: ReportsPartnerWalletView,
      },
      // CHARTS
      {
        name: "chartsalldata",
        path: "chartsalldata",
        component: ChartsAllDataView,
      },
      {
        name: "chartsnotinterested",
        path: "chartsnotinterested",
        component: ChartsNotInterestedView,
      },
      {
        name: "chartscompleatedorders",
        path: "chartscompleatedorders",
        component: ChartsCompleatedOrdersView,
      },
      {
        name: "chartscancelledorders",
        path: "chartscancelledorders",
        component: ChartsCancelledOrdersView,
      },
      // RMS
      {
        name: "rms_register",
        path: "rms_register",
        component: RmsRegistrationView,
      },
      {
        path: "",
        redirect: { name: "dashboard" },
      },
    ],
  },
  // {
  //   path: "/",
  //   beforeEnter: AuthGuardAlreadyLogged,
  //   redirect: { name: "dashboard" },
  // },
  {
    path: "/404",
    component: Page404Layout,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
