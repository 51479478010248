<template>
    <div class="RMS_Main">
        <RmsForm :bloodGroupArr="bloodGroupArr" :GenderArr="GenderArr"></RmsForm>
    </div>
</template>

<script>
import RmsForm from '@/components/template/RmsForm.vue'
export default{
    name: 'RMS',
    components:{
        RmsForm,
    },
    data () {
        return {
            bloodGroupArr : [],
            GenderArr: []
        }
    },
    methods:{
        async fetchBloodGroup () {
            fetch('https://bee.nutshell.repair/api/v1/blood_groups')
                .then(response => {
                    if (!response.ok) {
                    throw new Error('Network response was not OK');
                    }
                    return response.json();
                })
                .then(data => {
                    // Handle the successful response
                    this.bloodGroupArr = data.data;
                    // console.log(data.data);
                })
                .catch(error => {
                    // Handle any errors that occurred during the fetch
                    console.error('Error:', error);
                });
        },
        async fetchGender () {
            fetch('https://bee.nutshell.repair/api/v1/genders')
                .then(response => {
                    if (!response.ok) {
                    throw new Error('Network response was not OK');
                    }
                    return response.json();
                })
                .then(data => {
                    // Handle the successful response
                    this.GenderArr = data.data;
                    console.log(data.data); 
                })
                .catch(error => {
                    // Handle any errors that occurred during the fetch
                    console.error('Error:', error);
                });
        }
    },
    async created(){
        this.bloodGroupArr = await this.fetchBloodGroup();
        this.GenderArr = await this.fetchGender();
    }
}
</script>

<style scoped>
.RMS_Main{
    margin: 1rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* gap: 2rem; */
}
</style>