<template>
  <div class="auth-layout">
    <!--  -->

    <router-view />

    <!--  -->
  </div>
</template>

<style>
.auth-layout {
  min-height: 100vh;
  background-image: linear-gradient(to right, #66f7ff, #6666ff);
  /* background-color: linear-gradient(to right, #66f7ff, #6666ff); */
  /* background-color: #38414A; */
}
</style>
