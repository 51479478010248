import config from "@/config";

const BASE_URL = config.apiUrl;

export const GET_CATEGORIES_BY_CITIES = `${BASE_URL}/categories_by_cities`;
export const GET_CITIES = `${BASE_URL}/cities`;

export const POST_SEND_LOGIN_OTP = `${BASE_URL}/emp/send_login_otp`;
export const POST_SEND_LOGIN_CREDENTIALS = `${BASE_URL}/emp/login`;
export const POST_SEND_LOGOUT = `${BASE_URL}/emp/logout`;

export const GET_PARTNERS = `${BASE_URL}/emp/partners`;

export const GET_REPORTS_ALL_DATA = `${BASE_URL}/emp/reports/all_data`;
export const GET_REPORTS_NOT_INTERESTED = `${BASE_URL}/emp/reports/not_interested`;
export const GET_REPORTS_COMPLETED_ORDERS = `${BASE_URL}/emp/reports/completed_orders`;
export const GET_REPORTS_COMPLETED_ORDERS_AGGREGATOR = `${BASE_URL}/emp/reports/completed_orders_aggregator`;
export const GET_REPORTS_COMPLETED_ORDERS_IN_HOUSE = `${BASE_URL}/emp/reports/completed_orders_inhouse`;
export const GET_REPORTS_CANCELLED_ORDERS = `${BASE_URL}/emp/reports/cancelled_orders`;
export const GET_REPORTS_PARTNERS_WALLET_TRANSACTIONS = `${BASE_URL}/emp/reports/partners_wallet_transactions`;

export const POST_VENDORS = `${BASE_URL}/emp/vendors`;
