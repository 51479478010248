<template>
  <div>
    <!--  -->

    <div class="row">
      <div class="col-12">
        <h2>My Profile</h2>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4>Coming soon...!</h4>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>