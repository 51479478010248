<template>
     <div>
        <div class="header">
            <h2>Runner Registration Form</h2>
        </div>
        <div v-if="!ok" class="wrapper">
            <div class="toggle">
                <div class="content">
                <form  @submit.prevent="submitForm">
                    <div class="formgrp">
                        <label for="id">ID</label>
                        <input type="number" name="id" id="id" placeholder="Enter your ID" v-model="id" required>
                    </div>
                    <div class="formgrp">
                        <label for="name">Name</label>
                        <input type="text" name="name" id="name" placeholder="Enter your Name" v-model="name" required>
                </div>
                <div class="formgrp">
                    <label for="phone">Phone Number</label>
                    <input type="number" name="phone" id="phone" placeholder="Enter your Phone Number" v-model="number" required>
                </div>
                <div class="formgrp">
                    <label for="email">Email</label>
                    <input type="email" name="email" id="email" placeholder="Enter your Email"  v-model="email" required>
                </div>
                <div class="formgrp">
                    <label for="dob">Birth Date</label>
                    <input class="dob" type="date" name="dob" id="dob" :max="maxDate" v-model="selectedDate" placeholder="Date of Birth"  required>
                </div>
                <div class="aligngrp">
                    <div class="formgrp">
                        <label for="gender">Gender</label>
                        <select name="gender" id="gender" v-model="gender" required>
                            <option :key="gen.gender_id" v-for="gen in GenderArr" >{{ gen.gender_title }}</option>
                        </select>
                    </div>
                    <div class="formgrp">
                        <label for="bldgrp">Blood Group</label>
                        <select name="bldgrp" id="bldgrp" v-model="bloodGroup" required >
                            <option :key="Bgrp.blood_group_id" v-for="Bgrp in bloodGroupArr">{{ Bgrp.blood_group_title }}</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        <div class="imageholder">
            <img src="../../assets/images/register.svg" alt="" srcset="">
        </div>
    </div>
    <div class="button">
        <Button type="submit" class="btn btn-primary" @click="toggleslide" >Next</Button>
    </div>
    </div>

    <div v-if="ok" class="wrapper">
        <form  @submit.prevent="submitForm">
                    <div class="formgrp">
                        <label for="dl">DL Number</label>
                        <input type="number" name="dl" id="dl" placeholder="Enter your DL Number " v-model="DLNumber" required>
                    </div>
                    <div class="formgrp">
                        <label for="RC">RC (vehical details)</label>
                        <input type="text" name="RC" id="RC" placeholder="Enter your Name" v-model="RC" required>
                </div>
                <div class="formgrp">
                    <label for="aadhar">Aadhaar card</label>
                    <input type="number" name="aadhar" id="aadhar" placeholder="Enter your Aadhar Number" v-model="Aadhaar_Card" required>
                </div>
                <div class="formgrp">
                    <label for="pan">PAN Card</label>
                    <input type="number" name="pan" id="pan" placeholder="Enter your Pan Card"  v-model="Pan_Card" required>
                </div>
                <div  class="formgrp">
                    <label for="avatar">Choose a profile picture:</label>
                    <input type="file" @change="uploadImage" accept="image/*">
                    <img src="profile" alt="" srcset="">
                </div>
                <div class="formgrp">
                    <label for="verf">House verified</label>
                    <input type="checkbox" name="verf" id="verf"  v-model="Verified">
                </div>
                <div v-if="Verified" class="formgrp">
                    <label for="proof">Upload Housing proof</label>
                    <input type="file" id="proof" name="proof" accept="image/png, image/jpeg">
                </div>
            </form>
            <div class="button">
        <Button type="submit" class="btn btn-primary" @click="submitForm" >Submit</Button>
    </div>
    </div>
</div>
</template>

<script>
import { computed } from 'vue';
import { ref } from 'vue';

export default {
    name: 'RmsForm',
    props:{
        bloodGroupArr: Array,
        GenderArr: Array
    }, 
    computed(){
        console.log(this.GenderArr);
    },
    setup(){
        const id = ref(null);
        const name = ref(null);
        const number  = ref(null);
        const email = ref(null);
        const selectedDate = ref(null);
        const gender = ref(null);
        const bloodGroup = ref(null);
        const DLNumber = ref(null);
        const RC = ref(null);
        const Aadhaar_Card = ref(null);
        const Pan_Card = ref(null);
        const Verified = ref(null);
        var ok = ref(false);
        var profile = ref(null);
        const maxDate = computed(()=>{
            const currentDate = new Date();
            const maxYear = currentDate.getFullYear()-18;
            const maxMonth = currentDate.getMonth() + 1;
            const maxDay  = currentDate.getDate();

            return `${maxYear}-${maxMonth.toString().padStart(2, '0')}-${maxDay.toString().padStart(2, '0')}`;
        });
        const submitForm = () =>{
            if(!id.value || !name.value || !number.value || !email.value || !selectedDate.value || !gender.value || !bloodGroup.value){
                alert("Please enter all the field");
                return;
            }
            console.log(id.value,name.value,email.value);
            // Logic for backend
        }
        const toggleslide = () => {
            ok.value = true;
        }

        const uploadImage = (event) => {
            console.log(event.target.files[0])
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                profile = reader.result;
            };

            if (file) {
                reader.readAsDataURL(file);
            }
            console.log(profile.value);
        }

        return {
            selectedDate,
            maxDate,
            id,
            name,
            number,
            email,
            gender,
            bloodGroup,
            ok,
            submitForm,
            toggleslide,
            uploadImage,
            DLNumber,
            RC,
            Aadhaar_Card,
            Pan_Card,
            Verified
        }
    }
}
</script>
<style scoped>
.RMS_Main{
    margin: 1rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* gap: 2rem; */
}
.header > *{
    text-align: center;
}
.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    border: 1px solid rgb(155, 155, 155);
    padding: 2rem;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow:1px 1px 15px 1px rgb(146, 146, 146);
    margin-top: 1rem;
}
.toggle{
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}
.imageholder{
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    height: auto;
}
.imageholder > img{
    width: 350px;
}
.formgrp{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    width: 350px;
}
.formgrp > input, select{
    padding: 8px 10px;
    outline: none;
    border-radius: 5px;
    border: 2px solid rgba(80, 80, 80, 0.445);
}
.dob{
    padding: 5px 10px;
    border: 1px solid rgba(80, 80, 80, 0.445);
}
.aligngrp{
    display: flex;
    justify-content: space-between;
}
.aligngrp> *{
    width: 155px;
}
.button > *{
    width: 730px;
    margin: 0.5rem 0;
    background-color: #6c63ff;
}
.button button:hover{
    background-color: rgb(41, 72, 245)
    
}
</style>