import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.css";
import bootstrap from "bootstrap/dist/js/bootstrap.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserSecret, faTable } from "@fortawesome/free-solid-svg-icons";
// import { faTable } from "@fortawesome/sharp-regular-svg-icons";
import router from "./router";
import PrimeVue from "primevue/config";

//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";
//core
import "primevue/resources/primevue.min.css";
// CUSTOM
import "@/assets/css/app.css";

library.add(faUserSecret, faTable);
createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(router, bootstrap)
  .use(PrimeVue)
  .mount("#app");
