import jwtDecode from "jwt-decode";

export function AuthGuardAlreadyLogged(to, from, next) {
  let isAuthenticated = false;

  const token = localStorage.getItem("token");
  if (token != null) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.token_expires_at > currentTime) {
        // Token is not expired, allow access to the route
        isAuthenticated = true;
        // } else {
        // Token is expired, redirect to the login page
        // next("/auth/login");
        // next();
      }
    } catch (error) {
      // Invalid token, redirect to the login page
      // next("/auth/login");
      // next();
    }
    // } else {
    // No token found, redirect to the login page
    // next("/auth/login");
    //   next();
  }
  // next("/login");
  // next();

  if (isAuthenticated) {
    next("/dashboard");
  } else {
    next();
  }
}
