
<template>
<div>
  <!--  -->

    <div class="row mb-3 mt-3" v-if="fbWrapperShow">
      <div class="col-12">
        <div class="alert" role="alert" :class="fbDiv">
          <div v-html="fbMsg"></div>
        </div>
      </div>
    </div>

  <!--  -->
  </div>
</template>

<script>
export default({
  props: {
    fbWrapperShow: {
      type: Boolean,
      required: true
    },
    fbDiv: {
      type: String,
      required: true,
    },
    fbMsg: {
      required: true,
    }
  }
});
</script>