
<template>
<div>
  <!--  -->

    <div class="row mb-3 mt-3" v-if="loaderShow">
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>

  <!--  -->
  </div>
</template>

<script>
export default({
  props: {
    loaderShow: {
      type: Boolean,
      required: true
    }
  }
});
</script>