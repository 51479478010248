<template>
  <div>
    <!--  -->
  
    <div class="row">
      <div class="col-12">
        <h2>Reports - Partner Wallet</h2>
      </div>
    </div>
  
    <Loader :loaderShow="loaderShow"/>
  
    <Feedback :fbWrapperShow="fbWrapperShow" :fbDiv="fbDiv" :fbMsg="fbMsg"/>
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form class="row g-3">
              <div class="col-auto">
                <label for="fromDate">From Date*</label>
                <input type="date" class="form-control" id="fromDate" v-model="fromDate" required/> 
              </div>
              <div class="col-auto">
                <label for="toDate">To Date*</label>
                <input type="date" class="form-control" id="toDate" v-model="toDate" required/> 
              </div>
              <div class="col-auto">
                <label for="partners">Partners*</label>
                <select class="form-select" id="partners" v-model="selectedPartners" multiple required>
                  <option value="selectAll" :disabled="selectAllDisabled">Select All</option>
                  <option v-for="item in partners" :value="item.partner_id" :key="item.id">{{item.partner_fullname}}</option>
                </select>
              </div>
              <div class="col-auto">
                <label for="">&nbsp;</label>
                <button type="submit" @click=getReport class="btn btn-primary form-control" :disabled="buttonDisabled">Get Report</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row mt-3" v-if="tableRowShow">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">
              <button type="button" @click=exportToExcel class="btn btn-primary btn-sm">Export to Excel <span class="badge bg-light text-dark ms-1" v-if="dataCountLabel !== ''">{{dataCountLabel}}</span></button>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-sm" id="partnersWalletTransactionsTable">
                <thead class="table-secondary">
                  <tr>
                    <th>Transaction ID</th>
                    <th>Partner ID</th>
                    <th>Partner Fullname</th>
                    <th>Partner Mobile</th>
                    <th>Transaction Header</th>
                    <th>Order ID</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- tableRowData -->
                  <tr v-for="item in tableRowData" :key="item.id">
                    <td>{{ item.transaction_id }}</td>
                    <td>{{ item.partner_id }}</td>
                    <td>{{ item.partner_fullname }}</td>
                    <td>{{ item.partner_mobile }}</td>
                    <td>{{ item.transaction_header }}</td>
                    <td>{{ item.order_id }}</td>
                    <td class="text-end">{{ item.debit }}</td>
                    <td class="text-end">{{ item.credit }}</td>
                    <td class="text-end">{{ item.balance }}</td>
                    <td>{{ item.transaction_timestamp }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>

import { CommonServices } from "@/services/CommonServices";
import Loader from "@/components/Loader.vue";
import Feedback from "@/components/Feedback.vue";

import * as XLSX from "xlsx";
import axios from "axios";
import { GET_REPORTS_PARTNERS_WALLET_TRANSACTIONS } from "@/services/ApiConstants";

export default ({
  components: {
    Feedback,
    Loader,
  },
  data() {
      return {
        loaderShow: true,
        fbWrapperShow: false,
        fbDiv: '',
        fbMsg: '',
        fromDate: '',
        toDate: '',
        partners: [],
        selectedPartners: [],
        tableRowShow: false,
        tableRowData: [],
        buttonDisabled: false,
        dataCountLabel: '',
        selectAllDisabled: true,
      }
    },
    watch: {
      selectedPartners(newValues) {
        if (newValues.includes('selectAll')) {
          this.selectedPartners = this.partners.map(partners => partners.partner_id);
        }
    }
  },
    methods: {
      async fetchPartners() {
        let response = await CommonServices.getAllPartners();
        if(response.success) {
          this.partners = response.data;
        }
        if(this.partners.length > 1) {
          this.selectAllDisabled = false;
          this.partners.forEach(item => {
            item.partner_fullname = item.partner_fullname.toUpperCase();
          });
          this.partners.sort((a, b) => a.partner_fullname.localeCompare(b.partner_fullname));
        }
      },
      async getReport(e) {
        e.preventDefault();
        this.buttonDisabled = true;
        this.loaderShow = true;
        this.tableRowShow = false;
        this.fbWrapperShow = false;
        this.fbDiv = '';
        this.fbMsg = '';
        this.dataCountLabel = '';
        let partnersparams = "";
        for(let i = 0; i < this.selectedPartners.length; i++){
          partnersparams += ("&partner[]="+this.selectedPartners[i]);
        }
        let reqUrl = `${GET_REPORTS_PARTNERS_WALLET_TRANSACTIONS}?from_date=${this.fromDate}&to_date=${this.toDate}${partnersparams}`;
        let token = await CommonServices.getUserToken();
        console.log('reqUrl');
        console.log(reqUrl);
        axios.get(reqUrl, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          let responseData  = response.data;
          console.log('responseData');
          console.log(responseData);
          if(responseData.success) {
            const data = responseData.data;
            this.dataCountLabel = responseData.data_count;
            this.tableRowData = [];
            for (let x in data) {
              let order_id = data[x].order_id;
              let transaction_id = data[x].transaction_id;
              let transaction_header = data[x].transaction_header;
              let transaction_timestamp = data[x].transaction_timestamp;
              let debit = data[x].debit;
              let credit = data[x].credit;
              let balance = data[x].balance;
              let partner_id = data[x].partner_id;
              let partner_fullname = data[x].partner_fullname;
              let partner_mobile = data[x].partner_mobile;

              // 
              this.tableRowData = [...this.tableRowData, {
                "order_id" : order_id,
                "transaction_id" : transaction_id,
                "transaction_header" : transaction_header,
                "transaction_timestamp" : transaction_timestamp,
                "debit" : debit,
                "credit" : credit,
                "balance" : balance,
                "partner_id" : partner_id,
                "partner_fullname" : partner_fullname,
                "partner_mobile" : partner_mobile,
              }];
              this.buttonDisabled = false;
              this.loaderShow = false;
              this.tableRowShow = true;
              this.fbWrapperShow = false;
              this.fbDiv = '';
              this.fbMsg = '';
            }
          } else {
            let message = "";
            if(responseData.status == "not_ok") {
              let message1 = responseData.message;
              message = "<ul>";
              for (const key in message1) {
                message += "<li>"+message1[key][0]+"</li>";
              }
              message += "</ul>";
            } else {
              message = "<div>"+responseData.message+"</div>";
            }
            this.buttonDisabled = false;
            this.loaderShow = false;
            this.tableRowShow = false;
            this.fbWrapperShow = true;
            this.fbDiv = 'alert-danger';
            this.fbMsg = message;
          }
        })
        .catch(error => {
          // let response = JSON.parse(error.request.response);
          // let msg = response.message;
          console.log(error.response);
          let msg = "Server error";
          this.buttonDisabled = false;
          this.loaderShow = false;
          this.tableRowShow = false;
          this.fbWrapperShow = true;
          this.fbDiv = 'alert-danger';
          this.fbMsg = msg;
        });
      },
      exportToExcel(e) {
        e.preventDefault();
        const table = document.querySelector('#partnersWalletTransactionsTable')
        const worksheet = XLSX.utils.table_to_sheet(table)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        let fileName = "myData.xlsx"; 
        if(this.fromDate != "" && this.toDate != "") {
          fileName = "AFPL_partners_wallet_transactions_"+this.fromDate+"_to_"+this.toDate+".xlsx";
        } else {
          fileName = "AFPL_partners_wallet_transactions.xlsx";
        }
        XLSX.writeFile(workbook, fileName)
      },
    },
    async created(){
      await this.fetchPartners();
      this.loaderShow = false;
    }
  })

</script>